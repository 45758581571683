import React from 'react';
import qs from 'querystring';
import { withRouter as baseWithRouter } from 'next/router';

const withRouter = (WrappedComponent) => baseWithRouter(({router, ...props}) => {
  router.query = qs.parse(router.asPath.split(/\?/)[1]);

  return <WrappedComponent router={router} {...props} />;
});

export default withRouter;
import { POINT_TO_POINT_CODE, RENTAL_DAILY_CODE } from './constants';
import {
  TYPE_DRIVER_WITH_DRIVER,
  TYPE_TRANSMISSION_AUTOMATIC,
  TYPE_TRANSMISSION_MANUAL,
  TYPE_DRIVER_WITHOUT_DRIVER,
} from './type';

const AIRPORT_TRANSPORT_PREFIX = `/${POINT_TO_POINT_CODE}`;
const CAR_RENTAL_PREFIX = `/${RENTAL_DAILY_CODE}`;

const path = {
  base: '/',
  notFound: '/not-found',
  login: '/login',
  logout: '/logout',
  register: '/register',
  changePassword: '/change-password',

  airportTransport: {
    base: `${AIRPORT_TRANSPORT_PREFIX}`,

    instantBookingDashboard: `${AIRPORT_TRANSPORT_PREFIX}/instant-booking/dashboard`,

    supplier: `${AIRPORT_TRANSPORT_PREFIX}/supplier`,
    supplierNew: `${AIRPORT_TRANSPORT_PREFIX}/supplier/new`,
    supplierEdit: (supplierId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier/edit?supplierId=${supplierId}`,
    supplierZoneResolver: () =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier/zone-resolver`,

    supplierOperation: `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation`,
    supplierOperationRouteMeter: (
      supplierId,
      supplierBranchId,
      serviceCityId
    ) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/route/meter?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierOperationRouteZone: (supplierId, supplierBranchId, serviceCityId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/route/zone?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierOperationPickupProcedure: (
      supplierId,
      supplierBranchId,
      serviceCityId
    ) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/pickup-procedure?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierOperationPickupProcedureNew: (
      supplierId,
      supplierBranchId,
      serviceCityId
    ) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/pickup-procedure/new?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierOperationTerm: (supplierId, supplierBranchId, serviceCityId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/term?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierOperationParkingFee: (
      supplierId,
      supplierBranchId,
      serviceCityId
    ) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/parking-fee?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierOperationPickupLane: (supplierBranchId, serviceCityId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/pickup-lane?supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierOperationPickupLaneProcedure: (supplierBranchPickupLaneId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/pickup-lane/pickup-procedure?supplierBranchPickupLaneId=${supplierBranchPickupLaneId}`,
    supplierOperationPreTravelInfo: (
      supplierId,
      supplierBranchId,
      serviceCityId
    ) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/pre-travel-info?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierOperationPreTravelInfoNew: (
      supplierId,
      supplierBranchId,
      serviceCityId
    ) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/pre-travel-info/new?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierOperationSurchargeBySearchTime: (
      supplierId,
      supplierBranchId,
      serviceCityId
    ) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/surcharge-by-search-time?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierOperationKeyInformation: (
      supplierId,
      supplierBranchId,
      serviceCityId
    ) =>
      `${AIRPORT_TRANSPORT_PREFIX}/supplier-operation/key-information?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,

    carPool: `${AIRPORT_TRANSPORT_PREFIX}/car-pool`,
    routeMaintenance: `${AIRPORT_TRANSPORT_PREFIX}/route-maintenance`,
    routeZonalMaintenance: (routeId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/route-maintenance/route-zonal?routeId=${routeId}`,
    routeZonalPointMaintenance: (routeId, routeZonalId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/route-maintenance/route-zonal/route-zonal-point?routeId=${routeId}&routeZonalId=${routeZonalId}`,

    sku: `${AIRPORT_TRANSPORT_PREFIX}/sku`,
    skuNew: `${AIRPORT_TRANSPORT_PREFIX}/sku/new`,
    skuEdit: (productId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/sku/edit?productId=${productId}`,
    skuLeadTime: (productId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/sku/lead-time?productId=${productId}`,
    skuRouteZone: (productId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/sku/route/zone?productId=${productId}`,
    skuRouteMeter: (productId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/sku/route/meter?productId=${productId}`,
    skuRouteLeadTimeNew: (productId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/sku/route/lead-time/new?productId=${productId}`,
    skuRouteLeadTimeEdit: (productId, routeId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/sku/route/lead-time/edit?productId=${productId}&routeId=${routeId}`,
    skuSurchargeBySearchTime: (productId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/sku/surcharge-by-search-time?productId=${productId}`,

    booking: `${AIRPORT_TRANSPORT_PREFIX}/booking`,
    bookingDetail: (bookingCode) =>
      `${AIRPORT_TRANSPORT_PREFIX}/booking/detail?bookingCode=${bookingCode}`,
    bookingPickupManagement: `${AIRPORT_TRANSPORT_PREFIX}/booking-pickup-management`,

    fare: `${AIRPORT_TRANSPORT_PREFIX}/fare`,
    partnerChanges: `${AIRPORT_TRANSPORT_PREFIX}/partner-changes`,
    reporting: `${AIRPORT_TRANSPORT_PREFIX}/reporting`,

    driver: `${AIRPORT_TRANSPORT_PREFIX}/driver`,
    carPlate: `${AIRPORT_TRANSPORT_PREFIX}/car-plate`,

    allotment: `${AIRPORT_TRANSPORT_PREFIX}/allotment`,
    allotmentDetail: `${AIRPORT_TRANSPORT_PREFIX}/allotment/detail`,

    toll: `${AIRPORT_TRANSPORT_PREFIX}/toll`,

    // User
    user: `${AIRPORT_TRANSPORT_PREFIX}/user`,
    userNew: `${AIRPORT_TRANSPORT_PREFIX}/user/new`,
    userEdit: (userId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/user/edit?userId=${userId}`,

    // Vehicle
    carType: `${AIRPORT_TRANSPORT_PREFIX}/car-type`,
    carTypeNew: `${AIRPORT_TRANSPORT_PREFIX}/car-type/new`,
    carTypeEdit: (carTypeId) =>
      `${AIRPORT_TRANSPORT_PREFIX}/car-type/edit?carTypeId=${carTypeId}`,

    // Redemption
    redeem: `${AIRPORT_TRANSPORT_PREFIX}/ticket-redemption`,

    // Driver account update
    driverAccount: `${AIRPORT_TRANSPORT_PREFIX}/driver-account`,
  },

  carRental: {
    /**
     * Internal
     */
    base: `${CAR_RENTAL_PREFIX}`,

    // Allotment & Rates
    withDriverAllotmentAndRates: `${CAR_RENTAL_PREFIX}/inventory/with-driver/allotment-and-rates`,
    driverlessAllotment: `${CAR_RENTAL_PREFIX}/inventory/driverless/allotment`,
    driverlessRate: `${CAR_RENTAL_PREFIX}/inventory/driverless/rates`,
    driverlessAllotmentAvailability: `${CAR_RENTAL_PREFIX}/inventory/driverless/allotment-availability`,

    partnerChanges: `${CAR_RENTAL_PREFIX}/partner-changes`,
    inventoryMonitoring: `${CAR_RENTAL_PREFIX}/inventory-monitoring`,

    prebuy: `${CAR_RENTAL_PREFIX}/prebuy`,
    prebuyNew: `${CAR_RENTAL_PREFIX}/prebuy/new`,
    prebuyEdit: (prebuyId) =>
      `${CAR_RENTAL_PREFIX}/prebuy/edit?prebuyId=${prebuyId}`,

    // Operational Hour
    operationalHour: `${CAR_RENTAL_PREFIX}/operational-hour`,

    // Booking manifest
    bookingManifest: `${CAR_RENTAL_PREFIX}/booking-manifest`,
    bookingManifestSummary: `${CAR_RENTAL_PREFIX}/booking-manifest-summary`,
    bookingManifestDetail: (bookingCode) =>
      `${CAR_RENTAL_PREFIX}/booking-manifest/detail?bookingCode=${bookingCode}`,
    bookingManifestRentalDetail: (bookingCode) =>
      `${CAR_RENTAL_PREFIX}/booking-manifest/rental-detail?bookingCode=${bookingCode}`,

    // Supplier
    supplier: `${CAR_RENTAL_PREFIX}/supplier`,
    supplierNew: `${CAR_RENTAL_PREFIX}/supplier/new`,
    supplierEdit: (supplierId) =>
      `${CAR_RENTAL_PREFIX}/supplier/edit?supplierId=${supplierId}`,

    // Supplier Lead Time
    supplierLeadTime: (supplierId) =>
      `${CAR_RENTAL_PREFIX}/supplier/lead-time?supplierId=${supplierId}`,
    supplierWindowTime: (supplierId) =>
      `${CAR_RENTAL_PREFIX}/supplier/time-window?supplierId=${supplierId}`,

    // Supplier Operation
    supplierOperation: `${CAR_RENTAL_PREFIX}/supplier-operation`,

    // Supplier City
    supplierCity: `${CAR_RENTAL_PREFIX}/supplier-city`,
    supplierCityNew: (supplierId) =>
      `${CAR_RENTAL_PREFIX}/supplier-city/new?supplierId=${supplierId}`,
    supplierCityEdit: (supplierId, supplierBranchId, serviceCityId) =>
      `${CAR_RENTAL_PREFIX}/supplier-city/edit?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,

    // Supplier City Pool
    supplierCityPoolEdit: (
      supplierId,
      supplierBranchId,
      serviceCityId,
      poolId
    ) =>
      `${CAR_RENTAL_PREFIX}/supplier-city/pool/edit?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}&poolId=${poolId}`,
    supplierCityPoolNew: (supplierId, supplierBranchId, serviceCityId) =>
      `${CAR_RENTAL_PREFIX}/supplier-city/pool/new?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,

    // Supplier City Lead Time
    supplierCityLeadTime: (supplierId, supplierBranchId, serviceCityId) =>
      `${CAR_RENTAL_PREFIX}/supplier/city/lead-time?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierCityWindowTime: (supplierId, supplierBranchId, serviceCityId) =>
      `${CAR_RENTAL_PREFIX}/supplier-city/time-window?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,

    // Supplier City Driver
    supplierCityDriver: (supplierId, supplierBranchId, serviceCityId) =>
      `${CAR_RENTAL_PREFIX}/supplier-city/driver?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierCityDriverNew: (supplierId, supplierBranchId, serviceCityId) =>
      `${CAR_RENTAL_PREFIX}/supplier-city/driver/new?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierCityDriverEdit: (
      supplierId,
      supplierBranchId,
      serviceCityId,
      driverId
    ) =>
      `${CAR_RENTAL_PREFIX}/supplier-city/driver/edit?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}&driverId=${driverId}`,

    // Supplier City Product
    supplierCityProduct: `${CAR_RENTAL_PREFIX}/supplier-city-product`,
    supplierCityProductNew: (supplierId, supplierBranchId, serviceCityId) =>
      `${CAR_RENTAL_PREFIX}/supplier-city-product/new?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,
    supplierCityProductEdit: (
      supplierId,
      supplierBranchId,
      serviceCityId,
      productId
    ) =>
      `${CAR_RENTAL_PREFIX}/supplier-city-product/edit?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}&productId=${productId}`,
    supplierCityProductConfiguration: (
      supplierId,
      supplierBranchId,
      serviceCityId
    ) =>
      `${CAR_RENTAL_PREFIX}/supplier-city-product/configuration?supplierId=${supplierId}&supplierBranchId=${supplierBranchId}&serviceCityId=${serviceCityId}`,

    // City
    city: `${CAR_RENTAL_PREFIX}/city`,
    cityNew: `${CAR_RENTAL_PREFIX}/city/new`,
    cityEdit: (cityId) => `${CAR_RENTAL_PREFIX}/city/edit?cityId=${cityId}`,

    // User
    user: `${CAR_RENTAL_PREFIX}/user`,
    userNew: `${CAR_RENTAL_PREFIX}/user/new`,
    userEdit: (userId) => `${CAR_RENTAL_PREFIX}/user/edit?userId=${userId}`,

    // Car Type
    carType: `${CAR_RENTAL_PREFIX}/car-type`,
    carTypeNew: `${CAR_RENTAL_PREFIX}/car-type/new`,
    carTypeEdit: (carTypeId) =>
      `${CAR_RENTAL_PREFIX}/car-type/edit?carTypeId=${carTypeId}`,

    // Season
    season: `${CAR_RENTAL_PREFIX}/season`,
    seasonNew: `${CAR_RENTAL_PREFIX}/season/new`,
    seasonEdit: (seasonId) =>
      `${CAR_RENTAL_PREFIX}/season/edit?seasonId=${seasonId}`,

    // City Season
    citySeasonNew: (cityId) =>
      `${CAR_RENTAL_PREFIX}/city/season/new?cityId=${cityId}`,
    citySeasonWithDriverEdit: (cityId, seasonId) =>
      `${CAR_RENTAL_PREFIX}/city/season/edit-driver?cityId=${cityId}&seasonId=${seasonId}`,
    // TODO: add pages
    citySeasonWithoutDriverAutomaticEdit: (cityId, seasonId) =>
      `${CAR_RENTAL_PREFIX}/city/season/edit-no-driver/auto?cityId=${cityId}&seasonId=${seasonId}`,
    // TODO: add pages
    citySeasonWithoutDriverManualEdit: (cityId, seasonId) =>
      `${CAR_RENTAL_PREFIX}/city/season/edit-no-driver/manual?cityId=${cityId}&seasonId=${seasonId}`,

    // Driver Account
    driverAccount: `${CAR_RENTAL_PREFIX}/driver-account`,

    // Driver Reward
    driverReward: `${CAR_RENTAL_PREFIX}/driver-reward`,

    // Driver Feedback
    driverFeedback: `${CAR_RENTAL_PREFIX}/driver/feedback`,

    // Failed Payments
    failedPayments: `${CAR_RENTAL_PREFIX}/failed-payments`,

    // Documents
    document: `${CAR_RENTAL_PREFIX}/document`,
    documentNew: `${CAR_RENTAL_PREFIX}/document/new`,
    documentEdit: (documentId) =>
      `${CAR_RENTAL_PREFIX}/document/edit?documentId=${documentId}`,

    contactInformation: `${CAR_RENTAL_PREFIX}/contact-information`,
  },
};

export default {
  ...path,
  carRental: {
    ...path.carRental,
    citySeasonEdit: (cityId, seasonId, driverType, transmissionMode) => {
      if (driverType === TYPE_DRIVER_WITH_DRIVER) {
        return path.carRental.citySeasonWithDriverEdit(cityId, seasonId);
      } else if (
        driverType === TYPE_DRIVER_WITHOUT_DRIVER &&
        transmissionMode === TYPE_TRANSMISSION_AUTOMATIC
      ) {
        return path.carRental.citySeasonWithoutDriverAutomaticEdit(
          cityId,
          seasonId
        );
      } else if (
        driverType === TYPE_DRIVER_WITHOUT_DRIVER &&
        transmissionMode === TYPE_TRANSMISSION_MANUAL
      ) {
        return path.carRental.citySeasonWithoutDriverManualEdit(
          cityId,
          seasonId
        );
      }

      return path.notFound;
    },
  },
};

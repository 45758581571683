import { AriesReduxAction } from '@aries/common';
import { DEFAULT_LOCALE } from '../constants/locale';
import {
  SET_IS_EXTERNAL,
  SET_LANGUAGE,
  INIT_DATA,
  FETCH_DATA,
} from '../constants/PagePropertyConstant';

export interface PagePropertyReduxState {
  isExternal: boolean;
  language: string;
  isFetching: boolean;
}

export const INITIAL_STATE = {
  isExternal: true,
  language: DEFAULT_LOCALE,
  isFetching: true, // NB: set default true to avoid double request for children content
};

const reducers = (
  state: PagePropertyReduxState = INITIAL_STATE,
  action: AriesReduxAction
) => {
  switch (action.type) {
    case SET_IS_EXTERNAL:
      return {
        ...state,
        isExternal: action.value,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case FETCH_DATA:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case INIT_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducers;

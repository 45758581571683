// import NextRouter from 'next/router';
import { OIDCUserSelector } from '@traveloka/soya-auth';

import { post } from '../utils/RequestHandler';
import { getResponseData } from '../utils/ResponseHandler';

import { ID, EN } from '../constants/locale';
import { API_CHECK_USER, API_UPDATE_USER_LANGUAGE } from '../constants/apiUrl';
import {
  SET_IS_EXTERNAL,
  SET_LANGUAGE,
  INIT_DATA,
  FETCH_DATA,
} from '../constants/PagePropertyConstant';

import {
  getLocalLanguage,
  setLocalLanguage,
} from 'shared/utils/PagePropertyUtil';
import TranslationUtil from 'shared/utils/TranslationUtil';

import { TRAVELOKA_EMAIL_DOMAIN } from 'shared/constants/constants';
import { AriesReduxAction, AriesReduxThunkAction } from '@aries/common';

export const initData = (): AriesReduxAction => ({
  type: INIT_DATA,
});

export const fetchData = (isFetching: boolean): AriesReduxAction => ({
  type: FETCH_DATA,
  isFetching,
});

export const setIsExternal = (value: boolean): AriesReduxAction => ({
  type: SET_IS_EXTERNAL,
  value,
});

export const setLanguage = (language: string): AriesReduxAction => {
  setLocalLanguage(language);
  TranslationUtil.setLocale(language);

  return {
    type: SET_LANGUAGE,
    language,
  };
};

export const setInternalPage = (): AriesReduxThunkAction => {
  return dispatch => {
    dispatch(setIsExternal(false));
    dispatch(setLanguage(EN));
  };
};

export const setExternalPage = (): AriesReduxThunkAction => {
  return dispatch => {
    dispatch(setIsExternal(true));
    dispatch(setLanguage(ID));
  };
};

export const updateUserPreferredLanguage = (
  language: string
): AriesReduxThunkAction => {
  return dispatch => {
    post(API_UPDATE_USER_LANGUAGE, { language });

    dispatch(setLanguage(language.toUpperCase()));
  };
};

export const requestUserInfo = (): AriesReduxThunkAction => {
  return async (dispatch, getState) => {
    dispatch(fetchData(true));

    let language = getLocalLanguage();

    const { response, error } = await post(API_CHECK_USER);

    if (error) {
      const oidcSelector = OIDCUserSelector(getState());
      const email = oidcSelector.getUserEmail();

      if (email && email.indexOf(TRAVELOKA_EMAIL_DOMAIN) >= 0) {
        dispatch(setIsExternal(false));
      } else {
        dispatch(setIsExternal(true));
      }
    } else {
      const user = getResponseData(response).user;

      if (
        user.isInternal ||
        (user.email && user.email.indexOf(TRAVELOKA_EMAIL_DOMAIN) >= 0)
      ) {
        dispatch(setIsExternal(false));
      } else {
        dispatch(setIsExternal(true));
      }

      if (user.language) {
        language = user.language;
      }
    }

    dispatch(setLanguage((language || EN).toUpperCase()));
    dispatch(fetchData(false));
  };
};

export const TYPE_CURRENCY_AUD = 'AUD';
export const TYPE_CURRENCY_IDR = 'IDR';
export const TYPE_CURRENCY_BND = 'BND';
export const TYPE_CURRENCY_CNY = 'CNY';
export const TYPE_CURRENCY_EUR = 'EUR';
export const TYPE_CURRENCY_HKD = 'HKD';
export const TYPE_CURRENCY_JPY = 'JPY';
export const TYPE_CURRENCY_KRW = 'KRW';
export const TYPE_CURRENCY_MYR = 'MYR';
export const TYPE_CURRENCY_SGD = 'SGD';
export const TYPE_CURRENCY_THB = 'THB';
export const TYPE_CURRENCY_TWD = 'TWD';
export const TYPE_CURRENCY_USD = 'USD';
export const TYPE_CURRENCY_VND = 'VND';
export const TYPE_CURRENCY_PHP = 'PHP';
export const TYPE_CURRENCY_GBP = 'GBP';
export const TYPE_CURRENCY_TRY = 'TRY';
export const TYPE_CURRENCY_KHR = 'KHR';
export const TYPE_CURRENCY_LAK = 'LAK';
export const TYPE_CURRENCY_MMK = 'MMK';
export const TYPE_CURRENCY_CHF = 'CHF';
export const TYPE_CURRENCY_INR = 'INR';
export const TYPE_CURRENCY_NZD = 'NZD';
export const TYPE_CURRENCY_RUB = 'RUB';
export const TYPE_CURRENCY_AED = 'AED';
export const TYPE_CURRENCY_SAR = 'SAR';

export const CURRENCY_SELECTION = [
  { value: TYPE_CURRENCY_IDR, label: TYPE_CURRENCY_IDR },
  { value: TYPE_CURRENCY_AED, label: TYPE_CURRENCY_AED }, 
  { value: TYPE_CURRENCY_AUD, label: TYPE_CURRENCY_AUD },
  { value: TYPE_CURRENCY_BND, label: TYPE_CURRENCY_BND },
  { value: TYPE_CURRENCY_CHF, label: TYPE_CURRENCY_CHF },
  { value: TYPE_CURRENCY_CNY, label: TYPE_CURRENCY_CNY },
  { value: TYPE_CURRENCY_EUR, label: TYPE_CURRENCY_EUR },
  { value: TYPE_CURRENCY_GBP, label: TYPE_CURRENCY_GBP },
  { value: TYPE_CURRENCY_HKD, label: TYPE_CURRENCY_HKD },
  { value: TYPE_CURRENCY_INR, label: TYPE_CURRENCY_INR },
  { value: TYPE_CURRENCY_JPY, label: TYPE_CURRENCY_JPY },
  { value: TYPE_CURRENCY_KHR, label: TYPE_CURRENCY_KHR },
  { value: TYPE_CURRENCY_KRW, label: TYPE_CURRENCY_KRW },
  { value: TYPE_CURRENCY_LAK, label: TYPE_CURRENCY_LAK },
  { value: TYPE_CURRENCY_MMK, label: TYPE_CURRENCY_MMK },
  { value: TYPE_CURRENCY_MYR, label: TYPE_CURRENCY_MYR },
  { value: TYPE_CURRENCY_NZD, label: TYPE_CURRENCY_NZD },
  { value: TYPE_CURRENCY_PHP, label: TYPE_CURRENCY_PHP },
  { value: TYPE_CURRENCY_RUB, label: TYPE_CURRENCY_RUB },
  { value: TYPE_CURRENCY_SAR, label: TYPE_CURRENCY_SAR },
  { value: TYPE_CURRENCY_SGD, label: TYPE_CURRENCY_SGD },
  { value: TYPE_CURRENCY_THB, label: TYPE_CURRENCY_THB },
  { value: TYPE_CURRENCY_TRY, label: TYPE_CURRENCY_TRY },
  { value: TYPE_CURRENCY_TWD, label: TYPE_CURRENCY_TWD },  
  { value: TYPE_CURRENCY_USD, label: TYPE_CURRENCY_USD },
  { value: TYPE_CURRENCY_VND, label: TYPE_CURRENCY_VND },

];
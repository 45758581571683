import Helper from './Helper';
import { TYPE_CURRENCY_IDR } from 'shared/constants/currency';

export const RESPONSE_STATUS_FAILED = 'FAILED';
export const RESPONSE_STATUS_OK = 'OK';

export const getResponseData = response => {
  return response.data.data;
};

export const isResponseStatusFailed = response => {
  return getResponseData(response).status === RESPONSE_STATUS_FAILED;
};

export const isResponseDataNull = response => {
  return getResponseData(response).data === null;
};

// TODO: refactor all search result response to use this function
export const handleSearchResultsResponse = response => {
  const data = getResponseData(response);
  const result = data.searchResults || [];
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;

  return {
    data: result,
    page,
    totalPages,
  };
};

export const handleGetSupplierGroupsResponse = response => {
  return getResponseData(response).supplierGroups;
};

export const handleSearchBasicPriceAndOvertimeResponse = response => {
  const data = getResponseData(response);
  const { baseVehicleProductRateConfig, overtimeAddonRateConfig } = data;

  const productId = baseVehicleProductRateConfig.productId;
  const currency = baseVehicleProductRateConfig
    ? baseVehicleProductRateConfig.currency
    : TYPE_CURRENCY_IDR;
  const decimalPoint = baseVehicleProductRateConfig
    ? baseVehicleProductRateConfig.decimalPoint
    : 0;
  const basicRateValue = baseVehicleProductRateConfig
    ? baseVehicleProductRateConfig.rateValue
    : 0;
  const overtimeRateValue = overtimeAddonRateConfig
    ? overtimeAddonRateConfig.rateValue
    : 0;

  return {
    productId,
    currency,
    decimalPoint,
    basicPrice: {
      hasNoData: !baseVehicleProductRateConfig,
      rateValue: basicRateValue,
    },
    overtime: {
      hasNoData: !overtimeAddonRateConfig,
      rateValue: overtimeRateValue,
    },
  };
};

export const handleGetPoolsResponse = response => {
  return getResponseData(response).poolInformations;
};

export const handleGetPoolResponse = response => {
  return getResponseData(response).poolInformation;
};

export const handleGetVehiclesResponse = response => {
  return getResponseData(response).vehicles;
};

export const handleGetRoutesResponse = response => {
  return getResponseData(response).routes;
};

export const handleGetPaymentEntitiesResponse = response => {
  return getResponseData(response).paymentEntities;
};

export const handleGetCountriesResponse = response => {
  return getResponseData(response).countries;
};

export const handleGetMarketingManagersResponse = response => {
  return getResponseData(response).marketingManagers;
};

export const getResponseErrorCode = response => {
  return getResponseData(response).errorCode;
};

export const handleSearchVehicleInventoryResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const vehicleInventories = data.vehicleInventories.map(vehicleInventory => {
    const date = vehicleInventory.date;

    return {
      ...vehicleInventory,
      date: {
        year: date.year,
        month: date.month,
        day: date.day,
      },
    };
  });

  return {
    data: vehicleInventories,
    page,
    totalPages,
  };
};

export const handleSearchVehicleInventoryPartnerChangesResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const partnerChanges = data.vehicleInventoryChangeHistories.map(
    partnerChange => {
      const inventoryDate = partnerChange.inventoryDate;

      const historyDetail = partnerChange.historyDetail.map(historyDetail => {
        let changeProperty = historyDetail.changeProperty;
        const previousValue = [];
        const newValue = [];

        if (changeProperty === 'raw') {
          const prevValueObj = JSON.parse(historyDetail.previousValue);
          const newValueObj = JSON.parse(historyDetail.newValue);

          for (const key in prevValueObj) {
            previousValue.push(
              key.replace('_', ' ') + ': ' + String(prevValueObj[key])
            );
          }

          for (const key in newValueObj) {
            newValue.push(
              key.replace('_', ' ') + ': ' + String(newValueObj[key])
            );
          }

          // If raw then change property = partner change action type
          changeProperty = Helper.toTitleCase(partnerChange.actionType);
        } else {
          previousValue.push(String(historyDetail.previousValue));
          newValue.push(String(historyDetail.newValue));
        }

        return {
          newValue,
          previousValue,
          changeProperty,
        };
      });

      return {
        ...partnerChange,
        historyDetail,
        inventoryDate: {
          year: inventoryDate.year,
          month: inventoryDate.month,
          day: inventoryDate.day,
        },
      };
    }
  );

  return {
    data: partnerChanges,
    page,
    totalPages,
  };
};

export const handleGetProductAddonTemplateResponse = response => {
  const data = getResponseData(response);

  return data.addonDefinitions || [];
};

export const handleSearchProductAddonsResponse = response => {
  const data = getResponseData(response);
  const result = data.searchResults || [];
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;

  return {
    data: result,
    page,
    totalPages,
  };
};

export const handleSearchDriverlessRateSurchargeResponse = response => {
  const data = getResponseData(response);
  const result = data.poolSurcharges || [];
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;

  return {
    data: result,
    page,
    totalPages,
  };
};

export const handleSearchDriverlessRatePromoResponse = response => {
  const data = getResponseData(response);
  const result = data.promoConfigs || [];
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;

  return {
    data: result,
    page,
    totalPages,
  };
};

export const handleSearchVehicleBookingsResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const vehicleBookings = data.searchResults || [];

  return {
    data: vehicleBookings,
    page,
    totalPages,
  };
};

export const handleSearchVehicleBookingReportsResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const vehicleBookings = data.searchResults || [];

  return {
    data: vehicleBookings,
    page,
    totalPages,
  };
};

export const handleSearchVehicleBookingTripsResponse = response => {
  const data = getResponseData(response);
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;
  const vehicleBookingTrips = data.searchResults || [];

  return {
    data: vehicleBookingTrips,
    page,
    totalPages,
  };
};

export const handleSearchSupplierGroupsResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const supplierGroups = data.supplierGroups;

  return {
    data: supplierGroups,
    page,
    totalPages,
  };
};

export const handleSearchRoutesResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const routes = data.routes;

  return {
    data: routes,
    page,
    totalPages,
  };
};

export const handleSearchSupplierGroupRoutesResponse = response => {
  const data = getResponseData(response);
  const {
    supplierName,
    page,
    totalPages,
    supplierRoutes: supplierGroups,
  } = data;

  return {
    data: supplierGroups,
    supplierName,
    page,
    totalPages,
  };
};

export const handleSearchProductsResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const supplierGroupName = data.supplierGroupName;
  const routeName = data.routeName;
  const totalPages = data.totalPages;
  const products = data.products;

  return {
    data: products,
    supplierGroupName,
    routeName,
    page,
    totalPages,
  };
};

export const handleSearchProductConfigurationsResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const productConfigurations = data.sellingPolicies;

  return {
    data: productConfigurations,
    page,
    totalPages,
  };
};

export const handleSearchUsersResponse = response => {
  const data = getResponseData(response);
  const users = data.extranetUsers;
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: users,
    page,
    totalPages,
  };
};

export const handleSearchUserJobQueuesResponse = response => {
  const data = getResponseData(response);
  const userJobQueues = data.jobQueue;
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: userJobQueues,
    page,
    totalPages,
  };
};

export const handleSearchVehiclesResponse = response => {
  const data = getResponseData(response);
  const vehicles = data.vehicles;
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: vehicles,
    page,
    totalPages,
  };
};

export const handleSearchSeasonsResponse = response => {
  const data = getResponseData(response);
  const seasons = data.seasons;
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: seasons,
    page,
    totalPages,
  };
};

export const handleSearchPrebuysResponse = response => {
  const data = getResponseData(response);
  const prebuys = data.prebuys;
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: prebuys,
    page,
    totalPages,
  };
};

export const handleSearchDriversResponse = response => {
  const data = getResponseData(response);
  const drivers = data.drivers || [];
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: drivers,
    page,
    totalPages,
  };
};

export const handleSearchDriverPointsResponse = response => {
  const data = getResponseData(response);
  const drivers = data.driverPoints || [];
  const page = data.page;
  const totalPages = data.totalPages;
  const numberOfDisplayItems = data.numberOfDisplayItems;
  const totalItems = data.totalItems;

  return {
    data: drivers,
    page,
    totalPages,
    numberOfDisplayItems,
    totalItems,
  };
};

export const handleSearchLicensePlatesResponse = response => {
  const data = getResponseData(response);
  const licensePlates = data.licensePlates || [];
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: licensePlates,
    page,
    totalPages,
  };
};

export const handleSearchTollFeesResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const tollFees = data.searchResults || [];

  return {
    data: tollFees,
    page,
    totalPages,
  };
};

export const handleRouteMaintenancesResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const routes = data.routes || [];

  return {
    data: routes,
    page,
    totalPages,
  };
};

export const handleRouteZonalMaintenancesResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const routeZonals = data.routeZonals || [];

  return {
    data: routeZonals,
    page,
    totalPages,
  };
};

export const handleRouteZonalPointMaintenancesResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const routeZonalPoints = data.routeZonalPoints || [];

  return {
    data: routeZonalPoints,
    page,
    totalPages,
  };
};

export const handleGetDriversResponse = response => {
  return getResponseData(response).drivers;
};

export const handleGetSupplierCityUspResponse = response => {
  return getResponseData(response);
};

// TODO: need to be changed after known spec
export const handleGetSupplierKeyInformationResponse = response => {
  return getResponseData(response);
};

export const handleGetProductUspResponse = response => {
  return getResponseData(response);
};

export const handleGetSupplierCityPoolResponse = response => {
  return getResponseData(response);
};

export const handleUpdateSupplierCityPoolStatusResponse = response => {
  return getResponseData(response).result;
};

export const handleGetSupplierGroupDocumentsResponse = response => {
  return handleSearchResultsResponse(response);
};

export const handleGetSupplierRouteResponse = response => {
  return getResponseData(response).supplierRoute;
};

export const handleGetVehicleLicensePlatesResponse = response => {
  return getResponseData(response).licensePlates;
};

export const handleGetVehicleBookingDetailResponse = response => {
  return getResponseData(response);
};

export const handleGetVehicleBookingDetailInfoResponse = response => {
  return getResponseData(response).vehicleBookingDetail;
};

export const handleGetVehicleBookingDetailAddonInfoResponse = response => {
  const data = getResponseData(response);

  return {
    addons: data.addons || [],
    route: data.route || {},
  };
};

export const handleGetLandmarkResponse = response => {
  return getResponseData(response).landmark;
};

export const handleGetRouteAddonsResponse = response => {
  return getResponseData(response).routeAddons;
};

export const handleGetRouteAddonResponse = response => {
  return getResponseData(response).routeAddon;
};

export const handleGetAddonTemplatesResponse = response => {
  return getResponseData(response).addonPrices || [];
};

export const handleGetRouteResponse = response => {
  return getResponseData(response).route;
};

export const handleGetProductResponse = response => {
  return getResponseData(response).product;
};

export const handleGetSupplierResponse = response => {
  return getResponseData(response).supplier;
};

export const handleGetSupplierGroupResponse = response => {
  return getResponseData(response).supplierGroup;
};

export const handleGetVehicleResponse = response => {
  return getResponseData(response).vehicle;
};

export const handleGetUserResponse = response => {
  return getResponseData(response).user;
};

export const handleGetSeasonResponse = response => {
  return getResponseData(response).season;
};

export const handleGetUserGroupsResponse = response => {
  return getResponseData(response).groups;
};

export const handleGetMeterBasedVehicleProductRateResponse = response => {
  const responseData = getResponseData(response);
  const {
    baseRateValue,
    currency,
    decimalPoint,
    minimumRateValue,
    productId,
    surcharges,
  } = responseData;

  return {
    baseRateValue,
    currency,
    decimalPoint,
    minimumRateValue,
    productId,
    surcharges,
  };
};

export const handleGetMeterBasedRateConfigProductResponse = response => {
  return getResponseData(response);
};

export const handleGetUserMandatoryGroupsResponse = response => {
  return getResponseData(response).mandatoryGroups;
};

export const handleGetBanksResponse = response => {
  return getResponseData(response).banks;
};

export const handleGetBankBranchesResponse = response => {
  return getResponseData(response).branches;
};

export const handleGetPrebuyResponse = response => {
  return getResponseData(response).data;
};

export const handleGetDriverResponse = response => {
  return getResponseData(response).driver;
};

export const handleGetPrebuyCalculatedPriceResponse = response => {
  const priceCalculation = getResponseData(response).priceCalculation;

  return {
    baseAmount: priceCalculation.baseAmountPerItem,
    taxAmount: priceCalculation.taxPerItem,
    grossAmount: priceCalculation.grossAmountPerItem,
  };
};

export const handleGetPrebuyStatusRecapResponse = response => {
  return getResponseData(response).prebuyCountStatuses;
};

export const handleGetLeadTimeResponse = response => {
  return getResponseData(response).data;
};

export const handleGetTurnAroundTimeResponse = response => {
  return getResponseData(response).data;
};

export const handleSearchLeadTimesResponse = response => {
  const result = getResponseData(response);
  const data = result.searchResults || [];
  const page = result.page;
  const totalPages = result.totalPages;

  return {
    data,
    page,
    totalPages,
  };
};

export const handleGetVehicleBookingRentalDetailsResponse = response => {
  return getResponseData(response).vehicleBookingRentalDetails;
};

export const handleGetRouteZonalsResponse = response => {
  return getResponseData(response).routeZonals;
};

export const handleSearchSupplierServedZonesResponse = response => {
  const data = getResponseData(response);
  const servedZones = data.servedZones || [];
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: servedZones,
    page,
    totalPages,
  };
};

export const handleSearchSupplierPickupProceduresResponse = response => {
  const data = getResponseData(response);
  const pickupProcedures = data.pickupProcedures || [];
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: pickupProcedures,
    page,
    totalPages,
  };
};

export const handleSearchSupplierPreTravelInfoResponse = response => {
  const data = getResponseData(response);
  const preTravelInfo = data.preTravelInfo || [];
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: preTravelInfo,
    page,
    totalPages,
  };
};

// DEPRECATED
export const handleSearchSupplierTermsResponse = response => {
  const data = getResponseData(response);
  const pickupProcedures = data.generalTermAndConditions || [];
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: pickupProcedures,
    page,
    totalPages,
  };
};

export const handleGetSupplierTermResponse = response => {
  const data = getResponseData(response);

  return {
    contentAttributeId: data.contentAttributeId,
    contents: data.contents,
    supplierId: data.supplierId,
    routeId: data.routeId,
  };
};

export const handleSearchInventoryMonitoringsResponse = response => {
  const data = getResponseData(response);
  const inventoryMonitorings = data.inventoryMonitoringEntryList || [];
  const summary = data.inventoryMonitoringSummary || {};
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: inventoryMonitorings,
    summary,
    page,
    totalPages,
  };
};

export const handleSearchVehicleProductRateConfigsResponse = response => {
  const data = getResponseData(response);
  const vehicleProductRateConfigs = data.vehicleProductRateConfigs;
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: vehicleProductRateConfigs,
    page,
    totalPages,
  };
};

export const handleUploadFileResponse = response => {
  const data = getResponseData(response);

  return {
    url: data.url,
    key: data.key,
  };
};

export const handleGetExternalDriverRewardResponse = response => {
  const data = getResponseData(response);

  return {
    driverId: data.driverId,
    driverPoint: data.driverPoint,
  };
};

export const handleSearchDriverFeedbackResponse = response => {
  const data = getResponseData(response);
  const page = data.page;
  const totalPages = data.totalPages;
  const driverFeedback = data.searchResults || [];

  return {
    data: driverFeedback,
    page,
    totalPages,
  };
};

export const handleGetDriverListResponse = response => {
  return handleSearchResultsResponse(response);
};

export const handleGetSurchargeListResponse = response => {
  return getResponseData(response).addons;
};

export const handleSearchParkingFeeResponse = response => {
  return getResponseData(response);
};

export const handleGetIataList = response => {
  return getResponseData(response).airports;
};

export const handleSearchCarPoolsResponse = response => {
  const data = getResponseData(response);
  const result = data.carpools || [];
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;

  return {
    data: result,
    page,
    totalPages,
  };
};

export const handlePickupLaneSearchResultsResponse = response => {
  const data = getResponseData(response);
  const result = data.pickupLanes || [];
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;

  return {
    data: result,
    page,
    totalPages,
  };
};

export const handleSearchCarpoolAdminsResponse = response => {
  const data = getResponseData(response);
  const users = data.carpoolAdmins;
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: users,
    page,
    totalPages,
  };
};

export const handleGetPickupLaneProcedureResponse = response => {
  return getResponseData(response).pickupProcedure;
};

export const handleGetPickupDropoffFeeResponse = response => {
  const data = getResponseData(response);

  return {
    data,
  };
};

export const handleGetKeyInformationResponse = response => {
  return getResponseData(response);
};

export const handleGetDocumentsResponse = response => {
  return getResponseData(response).searchResults;
};

export const handleSearchDriverQueuesResponse = response => {
  const data = getResponseData(response);
  const driverQueues = data.driverQueues;
  const page = data.page;
  const totalPages = data.totalPages;

  return {
    data: driverQueues,
    page,
    totalPages,
  };
};

export const handleSearchSurchargeMeterBased = response => {
  const data = getResponseData(response);
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;
  const meterBasedSurcharges = data.surcharges || [];

  return {
    data: meterBasedSurcharges,
    page,
    totalPages,
  };
};

export const handleSearchPromoMeterBased = response => {
  const data = getResponseData(response);
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;
  const meterBasedPromos = data.promoConfigs || [];

  return {
    data: meterBasedPromos,
    page,
    totalPages,
  };
};

export const handleGetDriverQueueSummaryResponse = response => {
  const { currentStatuses, oneDayStatuses } = getResponseData(response);

  return { currentStatuses, oneDayStatuses };
};

export const handleGetSeasonCityDetailResponse = response => {
  return getResponseData(response);
};

export const handleGetCurrencyListResponse = response => {
  return getResponseData(response).currencies;
};

export const handleSearchPromoZoneBased = response => {
  const data = getResponseData(response);
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;
  const zoneBasedPromos = data.promoConfigs || [];

  return {
    data: zoneBasedPromos,
    page,
    totalPages,
  };
};

export const handleGetPromoOptionsResponse = response => {
  const data = getResponseData(response);
  const page = data.page || 1;
  const totalPages = data.totalPages || 1;
  const promoOptions = data.promoConfigs;

  return {
    data: promoOptions,
    page,
    totalPages,
  };
};

// DEPRECATED
export default {
  getResponseData,
  isResponseStatusFailed,

  // Select box response
  handleGetBanksResponse,
  handleGetBankBranchesResponse,
  handleGetCountriesResponse,
  handleGetMarketingManagersResponse,
  handleGetPaymentEntitiesResponse,
  handleGetRoutesResponse,
  handleGetSupplierGroupsResponse,
  handleGetUserGroupsResponse,
  handleGetUserMandatoryGroupsResponse,
  handleGetVehiclesResponse,
  handleGetDriversResponse,
  handleGetVehicleLicensePlatesResponse,
  handleGetRouteZonalsResponse,

  // Search response
  handleSearchDriversResponse,
  handleSearchDriverPointsResponse,
  handleSearchLicensePlatesResponse,
  handleSearchPrebuysResponse,
  handleSearchProductsResponse,
  handleSearchProductConfigurationsResponse,
  handleSearchRoutesResponse,
  handleSearchSeasonsResponse,
  handleSearchSupplierGroupsResponse,
  handleSearchUsersResponse,
  handleSearchUserJobQueuesResponse,
  handleSearchVehicleBookingsResponse,
  handleSearchVehicleBookingTripsResponse,
  handleSearchVehiclesResponse,
  handleSearchVehicleInventoryResponse,
  handleSearchVehicleInventoryPartnerChangesResponse,
  handleSearchSupplierServedZonesResponse,
  handleSearchSupplierPickupProceduresResponse,
  handleSearchSupplierTermsResponse,
  handleSearchInventoryMonitoringsResponse,
  handleSearchVehicleProductRateConfigsResponse,
  handleSearchVehicleBookingReportsResponse,
  handleSearchDriverFeedbackResponse,

  // Entity response
  handleGetAddonTemplatesResponse,
  handleGetVehicleBookingRentalDetailsResponse,
  handleGetDriverResponse,
  handleGetLandmarkResponse,
  handleGetLeadTimeResponse,
  handleGetProductResponse,
  handleGetPrebuyResponse,
  handleGetPrebuyCalculatedPriceResponse,
  handleGetPrebuyStatusRecapResponse,
  handleGetRouteResponse,
  handleGetRouteAddonResponse,
  handleGetRouteAddonsResponse,
  handleGetSeasonResponse,
  handleGetSupplierResponse,
  handleGetSupplierGroupResponse,
  handleSearchSupplierGroupRoutesResponse,
  handleGetUserResponse,
  handleGetVehicleResponse,
  handleGetVehicleBookingDetailResponse,
  handleGetVehicleBookingDetailAddonInfoResponse,
  handleGetVehicleBookingDetailInfoResponse,
  handleGetSurchargeListResponse,

  // Upload File
  handleUploadFileResponse,

  // External Page
  handleGetExternalDriverRewardResponse,
};

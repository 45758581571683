import { INITIAL_STATE } from '../reducers/PagePropertyReducer';

const getPagePropertyState = (state) => {
  const pageState = state && state.pageProperty ? state.pageProperty : {};
  
  return {
    isExternal: pageState.isExternal !== undefined && pageState.isExternal !== null ? pageState.isExternal : INITIAL_STATE.isExternal,
    locale: pageState.language ? pageState.language : INITIAL_STATE.language,
    isFetching: pageState.isFetching !== undefined && pageState.isFetching !== null ? pageState.isFetching : INITIAL_STATE.isFetching,
  };
};

export default getPagePropertyState;
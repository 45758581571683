import React from 'react';
import NextRouter from 'next/router';
import {
  Button,
  Card,
  CardBody,
  FlexBox,
  Icon,
} from '@traveloka/soya-components';

import path from '../../constants/path';

const AccessDeniedBox = (props) => {
  return (
    <FlexBox halign={'center'} valign={'middle'} marginTop={'md'}>
      <Card width={'500px'} style={{textAlign: 'center'}}>
        <CardBody>
          <Icon icon="alert-circle" size="48px" color="#ffbd41" />
          <h3>{'Access Denied'}</h3>
          {'If you have a problem regarding access, please contact your administrator.'}
          <br />
          <br />
          <Button onClick={() => { NextRouter.push(path.base) }} color={'orange'}>{'Back To Home'}</Button>
          <br />
          <br />
          <br />
        </CardBody>
      </Card>
    </FlexBox>
  );
};

export default AccessDeniedBox;